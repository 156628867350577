import type { MY24HeroBannerProps } from '@/components/sections/my24/home/hero-banner';
import { IMY24LineupCarousel } from '@/components/sections/my24/home/lineup-carousel';
import { getCta } from '@/contents/lcv/product/my24/cta';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/my24/models';

export const META_TAG_DATA = {
  title: 'ອີຊູຊຸລາວ - Isuzu TIS Laos',
  description: 'ຕິດຕາມຂ່າວສານ ແລະ ໂປຼໂມຊັນໃຫມ່ໆ ຂອງອີຊູຊຸໄດ້ທີ່ນີ້',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HERO_BANNER_DATA: MY24HeroBannerProps = {
  headline: {
    title: 'ອີຊູຊຸເຊວຊ໌ລາວ Isuzu TIS Lao Official',
  },
  items: [
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີແມັກ ວີ-ຄຣອສ 4x4 <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltacd57093f4ff79d5/6789cddf79558dbc404ce514/laos-my25-banner-v-cross.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9244de8e4052fb1e/6790ad507d9db22bfdc08d0d/laos-my25-banner-v-cross-desktop.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4721b6e8faee609d/651528c9b16d6d5596554560/logo-new-isuzu-v-cross-4x4-white.png',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 246,
        height: 72,
      },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີແມັກ ໄຮ​ແລນ​ເດີ 4 ປະ​ຕູ <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4e2f34cd8b68888b/6789d207d1f9194f228bb6dc/laos-my25-banner-4door.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt91fda02d890af6b1/6789d206ee8f3868d0aa2037/laos-my25-banner-4door-desktop.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt90599ea4859b6c8c/64fd7594b169adb40804b401/logo-new-isuzu-hi-lander-white.svg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີແມັກ ສະປ໊າກ <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt47b9bdde3f80638e/6789d59f9d626e85d911b2d8/laos-my25-banner-spark.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta190f8c255a3ddc3/6789d59f259b9a6b9526451b/laos-my25-banner-spark-desktop.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd531cf0bb3b4ed81/64fd71a1bb60f75a2b4803cc/logo-new-isuzu-spark-white.svg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      theme: 'light',
      title: 'ຈຸດສູງສຸດໃໝ່… ກັບຊີວິດທີ່ເໜືອກວ່າ',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6d3212ed2ac76980/678f3ba8a5499bcaa014bc93/banner-mu-x.jpg',
        alt: 'The New MU-X be UNCHARTED',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte2ac11dfd1a5b66f/678f3ba858fb6d1e8d8107d2/banner-mu-x-desktop.jpg',
        alt: 'The New MU-X be UNCHARTED',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc20c30606b72e356/678f3d88d02e39b18e9e06a7/logo.png',
        alt: 'The New MU-X be UNCHARTED',
        width: 300,
        height: 70,
      },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ MU-X',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
  ],
};

export const LINEUP_CAROUSEL_DATA: IMY24LineupCarousel = {
  active: true,
  headline: {
    title: 'ສຳຫຼວດລົດ',
  },
  items: HOME_LINEUP_DATA,
};

export const CTA_DATA = {
  ...getCta('home'),
};
